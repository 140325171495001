import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import gql from "graphql-tag";
export var CREATE_PRODUCT = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($input: ProductInput) {\n    createSellerProduct(input: $input) {\n      isSuccess\n      message\n      is_error\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_PRODUCT = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation($input: ProductInput) {\n    updateSellerProduct(input: $input) {\n      isSuccess\n      message\n      is_error\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_PRODUCT_PUBLISHED = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($_id: ID, $is_published: Boolean) {\n    updateSellerProductPublished(_id: $_id, is_published: $is_published) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var DELETE_PRODUCT = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($_id: ID) {\n    deleteSellerProduct(_id: $_id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var GET_PRODUCT_LIST = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query($page: Int, $pageSize: Int, $search: String) {\n    product_list(page: $page, pageSize: $pageSize, search: $search) {\n      isSuccess\n      message\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        productId\n        productName\n        category {\n          originalName\n        }\n        priceShow\n        mainImage\n        stock\n        is_published\n        created_at\n      }\n    }\n  }\n"])));
export var GET_PRODUCT = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query($id: String) {\n    product(id: $id) {\n      isSuccess\n      message\n      data {\n        _id\n        productId\n        productName\n        stock\n        price\n        shipping_price\n        properties {\n          id\n          title\n          list {\n            id\n            title\n          }\n        }\n        type\n        desc\n        skus {\n          skuId\n          skuName\n          price\n          stock\n        }\n        categoryId\n        category {\n          categoryId\n          originalName\n        }\n        priceShow\n        mainImage\n        imageList {\n          path\n          default\n          highlight\n        }\n        size {\n          weight\n          width\n          height\n          long\n        }\n        is_published\n        created_at\n      }\n    }\n  }\n"])));